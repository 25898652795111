import React from 'react'
import amazonlogo from './logos/amazonmusic.png'
import anghami from './logos/anghami.png'
import applmusic from './logos/Apple_Music.png'
import audiomack from './logos/audiomack.png'
import Boomplaylogo from './logos/Boomplay_logo.png'
import Deezer_Logo from './logos/Deezer_Logo.png'
import facebook_logo from './logos/facebook_logo.png'
import napster from './logos/napster.png'
import Pandora_Radio from './logos/Pandora_Radio.png'
import Spotify_Logo_RGB_White from './logos/Spotify_Logo_RGB_White.png'
import TikTok from './logos/TikTok.png'
import youtubelogo from './logos/youtubelogo.png'
import './connections.css'
import './connections-resp.css'
const Connections = () => {

    const logos = [
        amazonlogo,
        anghami,
        applmusic,
        audiomack,
        Boomplaylogo,
        Deezer_Logo,
        facebook_logo,
        napster,
        Pandora_Radio,
        Spotify_Logo_RGB_White,
        TikTok,
        youtubelogo
    ]
  return (
    <section className="text-gray-600 body-font">
  <div className="connection_parent_parent container px-5 py-24 mx-auto">
    <div  className="  connection_parent flex flex-wrap -m-2">
    {
        logos.map((url) => (
            <div  className="p-2 ">
        <div  className=" hover:bg-gray-900 h-full flex items-center border-gray-200 border p-4 ">
          <img
          className='logos_image'
            alt="team"
            src={url}
          />
        </div>
      </div>
        ))
    }
    </div>
  </div>
</section>

  )
}

export default Connections