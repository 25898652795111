import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import './header.css'
function Header() {
  return (
    <Navbar className='navBar' collapseOnSelect expand="lg"  variant="dark" >
      <Container>
        <Navbar.Brand href="#home"> VISIR-LOGO </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link className='navLink' href="/Accueil">Accueil</Nav.Link>
            <Nav.Link className='navLink' href="/Distrubition">Distrubition</Nav.Link>
            <Nav.Link className='navLink' href="/Maisondisk">Maison disk</Nav.Link>
            <Nav.Link className='navLink' href="/Maisondisk">Labels</Nav.Link>
            <NavDropdown className='navLink' title="Services" id="collasible-nav-dropdown">
              <NavDropdown.Item className='navLink dropdown_item' href="/Services/Productionsvisuels">Productions visuels</NavDropdown.Item>
              <NavDropdown.Item className='navLink dropdown_item' href="/Services/Promotionmusic">Promotion music</NavDropdown.Item>
              <NavDropdown.Item className='navLink dropdown_item' href="/Services/Studio">
                Studio
              </NavDropdown.Item>
            </NavDropdown>
            <Nav.Link className='navLink' href="/Tarifications">Tarifications</Nav.Link>
          </Nav>
          <Nav>
            <Nav.Link className='navLink' href="/Assistance">Assistance</Nav.Link>
            <Nav.Link className='navLink' eventKey={2} href="/Apropos">Apropos</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Header;