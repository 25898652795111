import React from 'react'
import './home.css'
import Header from '../../component/header/header'
import LargeButton from '../../component/buttons/large_button/largeButton'
import './home-resp.css'
import Connections from '../../component/connections/connections'
import Footer from '../../component/footer/footer'

const Home = () => {
  return (
      <div class="parent_home">
      <Header/>
      <div className="landing_page">
        <div className="title_div">
            <p>
            DISTRIBUTION DE MUSIQUE  & SERVICES AUX ARTISTES 
            </p>
        </div>
        <div className="register">
        <LargeButton
          content = "inscription gratuite"
        />
        </div>
      </div>
      <div className="connections">
      <div className="title_connections">
        <p>
          NOUS SOMMES CONNECTÉS À
        </p>
      </div>
      <Connections/>
      </div>
      <Footer/>
    </div>

  )
}

export default Home