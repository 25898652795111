import React from 'react'
import "./largeButton.css"
const LargeButton = ({content}) => {
  return (
    <button className=" largeButton text-white  font-bold uppercase text-base px-8 py-3 rounded-full shadow-md hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button">
            {content}
    </button>
  )
}

export default LargeButton